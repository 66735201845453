import './App.css';
import HeroDesktop from './assets/hero-desktop.png';
import HeroMobile from './assets/hero-mobile.png'
import TrailsMap from './assets/trail_map.png'
import FBLogo from './assets/f_logo_RGB-Blue_58.png'
import IGLogo from './assets/Instagram_Glyph_Gradient.png'

function App() {
  return (
    <div className="App">
      <div className="hero">
        <div className="hero-text">
          <header className="header1">
            Boundary Road Trails
          </header>
          <div className="header3">
            Portage Township - Houghton County
          </div>
          <div className="menu-wrapper">
            <a className="menu-item-home" href="#home">Home</a>
            <a className="menu-item" href="#location">Location</a>
            <a className="menu-item" href="#map">Trails Map</a>
            <a className="menu-item" href="#update">Updates</a>
            <a className="menu-item" href="https://www.gofundme.com/f/boundary-road-trails-grooming-202223" target="_blank">Support Us</a>
            <a className="menu-item" href="#contact">Contact</a>
          </div>
        </div>
        <img src={HeroDesktop} className="hero-image-desktop"/>
        <img src={HeroMobile} className="hero-image-mobile"/>
      </div>
      <div className="trails-body">
        <a id="home"></a>
        <div className="trails-wrapper">
          <p>Boundary Road Trails is free and open to the public for all seasons of silent sports - cross country skiing, snow shoeing, fat tire biking, mountain biking, hiking. Bring your furry friend - we're dog friendly!</p>
          <p>The Boundary Road Trails feature scenic overlooks of the Pilgrim River. New starting the winter 2021-22 season - the logging road sections will be groomed for cross country!</p>
          <p>Help us keep these trails groomed and maintained all winter.</p>
          <form action="https://www.gofundme.com/f/boundary-road-trails-grooming-202223">
            <input className="form-button" type="submit" value="Support Us" />
          </form>
        </div>
        <div className="location-wrapper">
          <a id="location" className="header2">Trail Location</a>
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5436.441920132459!2d-88.58860103702129!3d47.05551574448544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d50c9ccb99296d7%3A0x8b9ae6354d7f4e6!2sBoundary%20Road%20Trails!5e0!3m2!1sen!2sus!4v1659319630837!5m2!1sen!2sus" 
            className="map" 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="map-wrapper">
          <a id="map" className="header2">Trails Map</a>
          <img src={TrailsMap} className="trails-map"></img>
        </div>
        <div className="updates-wrapper">
          <a id="update" className="header2">Updates</a>
          <div class="fb-page" data-href="https://www.facebook.com/Boundary.Road.Trails" data-tabs="timeline" data-width="500" data-height="600" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true">
            <blockquote cite="https://www.facebook.com/Boundary.Road.Trails" class="fb-xfbml-parse-ignore">
              <a href="https://www.facebook.com/Boundary.Road.Trails">Boundary Road Trails</a>
            </blockquote>
          </div>
        </div>
        <div className="contacts-wrapper">
          <a className="header2" id="contact">Contact</a>
          <div className="contact-wrapper">
            <div className="contact-logo-2">p: </div>
            <a href="tel:906-370-2911" className="contact-text">906-370-2911</a>
          </div>
          <div className="contact-wrapper">
            <img src={FBLogo} className="contact-logo"/>
            <a className="contact-text" href="https://www.facebook.com/Boundary.Road.Trails/?ref=boundary_road_trails_website">Follow us on Facebook!</a>
          </div>
          <div className="contact-wrapper">
            <img src={IGLogo} className="contact-logo"/>
            <a className="contact-text" href="https://www.instagram.com/boundaryroadtrails/">Follow us on Instagram!</a>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default App;
